import * as React from "react";
// import "./Location.css";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import { Link } from "gatsby";

import Phone from "../../images/phone_black.svg";
import Stopwatch from "../../images/stopwatch.svg";
import Message from "../../images/message.svg";
import Point from "../../images/map_black.svg";
import { useEffect, useState } from "react";
import useScreenSize from "use-screen-size";
// @ts-ignore
const Contacts = ({ intl }) => {
  const [sizeOfDisplay, setSizeOfDisplay] = useState(1280);
  const size = useScreenSize();

  useEffect(() => setSizeOfDisplay(size.width), [size.width]);
  return (
    <div className="grid xs:justify-center  sm:pl-0">
      <div className="grid  lg:justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
        <div className="ml-[0.2rem] mt-[2.5rem] mb-[2rem] "></div>
        <div className="grid justify-start mb-[2rem] h-[3rem] ">
          <h2 className="lg:text-[2rem] sm:text-[1.75rem] xs:text-[1.25rem] font-semibold leading-5">
            {parse(intl.formatMessage({ id: "Contacts" }))}
          </h2>
        </div>
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 justify-between xl:gap-[4rem] lg:gap-[2rem] xs:gap-[4rem] items-center ">
          <div className="xl:w-[37rem] lg:w-[30.5rem] xl:h-[23rem] lg:h-[19rem] sm:h-[20.75rem] xs:h-[20rem] xs:w-auto items-start justify-start px-[2rem]  bg-white border border-[#618EE4] border-opacity-25 rounded-[1rem] flex flex-col gap-2  ">
            <h4 className="xl:mt-[2.5rem] lg:mt-[1.5rem] sm:mt-[2.5rem] xs:mt-[1.5rem] font-semibold text-[1.25rem]">
              {parse(intl.formatMessage({ id: "LaboratoryTumar" }))}
            </h4>
            <div className="mt-[1.5rem] flex justify-start items-start ">
              <img src={Point} alt="" className="mt-[0.2rem]" />
              <p className="lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4">
                {parse(intl.formatMessage({ id: "Location.LocationAddress" }))}
              </p>
            </div>
            <div className="mt-[1.5rem] flex justify-start items-start  ">
              <img className="mt-[0.2rem]" src={Stopwatch} alt="" />
              <p className="lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4">
                {parse(
                  intl.formatMessage({ id: "Location.LocationWorkHours" })
                )}
              </p>
            </div>
            <div className="mt-[1.5rem] flex justify-start items-center  ">
              <img src={Message} alt="" />
              <p className="lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4">
                {parse(intl.formatMessage({ id: "Location.LocationMail" }))}
              </p>
            </div>
            <div className="mt-[1.5rem] flex justify-start items-center  ">
              <img src={Phone} alt="" />
              <p className=" lg:text-[1.5rem] xs:text-[1rem] leading-4 font-medium  ml-4">
                8 (727) 33 33 003
              </p>
            </div>
          </div>
          <div className="xs:w-auto  rounded-[1rem] grid justify-left  ">
            {sizeOfDisplay >= 1280 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11630.752518996322!2d76.92578668833009!3d43.2160302285951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f307c71953f%3A0x3a7913cb3982fc67!2sThe%20Ritz-Carlton%2C%20Almaty!5e0!3m2!1sen!2skz!4v1676277755193!5m2!1sen!2skz"
                width="589"
                height="372"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay >= 1024 && sizeOfDisplay < 1280 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11630.752518996322!2d76.92578668833009!3d43.2160302285951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f307c71953f%3A0x3a7913cb3982fc67!2sThe%20Ritz-Carlton%2C%20Almaty!5e0!3m2!1sen!2skz!4v1676277755193!5m2!1sen!2skz"
                width="486"
                height="307"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay >= 767 && sizeOfDisplay < 1024 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11630.752518996322!2d76.92578668833009!3d43.2160302285951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f307c71953f%3A0x3a7913cb3982fc67!2sThe%20Ritz-Carlton%2C%20Almaty!5e0!3m2!1sen!2skz!4v1676277755193!5m2!1sen!2skz"
                width="680"
                height="460"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay < 768 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11630.752524450318!2d76.9257867!3d43.2160302!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f307c71953f%3A0x3a7913cb3982fc67!2sRitz-Carlton!5e0!3m2!1sru!2skz!4v1676288412838!5m2!1sru!2skz"
                width="300"
                height="320"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Contacts);
